"use strict";
exports.__esModule = true;
var base64_arraybuffer_1 = require("base64-arraybuffer");
var CrossDomainToken = /** @class */ (function () {
    function CrossDomainToken(userUuid, sessionId, freshness) {
        this.userUuid = userUuid;
        this.sessionId = sessionId;
        this.freshness = freshness;
    }
    CrossDomainToken.prototype.encode = function () {
        var buffer = new ArrayBuffer(4 * 4 + 36);
        var u32view = new Uint32Array(buffer);
        u32view[0] = this.sessionId % Math.pow(2, 32);
        u32view[1] = Math.floor(this.sessionId / Math.pow(2, 32));
        u32view[2] = this.freshness % Math.pow(2, 32);
        u32view[3] = Math.floor(this.freshness / Math.pow(2, 32));
        var u8view = new Uint8Array(buffer, 4 * 4);
        var uuidEncoded = new TextEncoder().encode(this.userUuid); // no encodeInto for you :(
        uuidEncoded.forEach(function (byte, i) { return (u8view[i] = byte); });
        return base64_arraybuffer_1.encode(buffer)
            .replaceAll("+", "-")
            .replaceAll("/", "_")
            .replaceAll("=", " ")
            .trimEnd();
    };
    CrossDomainToken.prototype.isValid = function () {
        return (this.sessionId && this.userUuid && this.freshness > new Date().getTime());
    };
    CrossDomainToken.decode = function (token) {
        try {
            var buffer = base64_arraybuffer_1.decode(token.replaceAll("_", "/").replaceAll("-", "+"));
            var u32view = new Uint32Array(buffer);
            var u8view = new Uint8Array(buffer, 4 * 4, 36);
            var userUuid = new TextDecoder("utf8").decode(u8view);
            return new CrossDomainToken(userUuid, u32view[0] + u32view[1] * Math.pow(2, 32), u32view[2] + u32view[3] * Math.pow(2, 32));
        }
        catch (_a) {
            return null;
        }
    };
    return CrossDomainToken;
}());
exports["default"] = CrossDomainToken;
