"use strict";
exports.__esModule = true;
exports.UserNamespaces = exports.Namespaces = void 0;
var COOKIE_NAME = "jayid";
var TOKEN_PARAM_NAME = "jaytk";
var SESSION_KEY_NAME = "jaysession";
var CUSTOM_EVENT = "__happened";
/**
 * The object that manages the "namespace real estate": cookie names, storage keys, etc... The
 * objective is to isolate data from different source systems.
 */
var Namespaces = /** @class */ (function () {
    function Namespaces(sourceSystem) {
        this.base = "jay::" + sourceSystem;
        this.cookieName = this.base + "::" + COOKIE_NAME;
        this.sessionKey = this.base + "::" + SESSION_KEY_NAME;
        this.tokenParam = "" + TOKEN_PARAM_NAME + sourceSystem;
        this.customEvent = "" + CUSTOM_EVENT + sourceSystem;
    }
    return Namespaces;
}());
exports.Namespaces = Namespaces;
/**
 * The object that manages the "namespace real estate": cookie names, storage keys, etc... The
 * objective is to isolate data from different users in different source systems.
 */
var UserNamespaces = /** @class */ (function () {
    function UserNamespaces(namespaces, userUuid) {
        this.base = namespaces.base + "::" + COOKIE_NAME + "::" + userUuid;
        this.triggerPrefix = this.base + "::triggers::";
        this.eventPrefix = this.base + "::events::";
    }
    /**
     * Generates a key in LocalStorage for an event that was pushed in a specific time.
     * @param pushedAt the time the event was pushed to the persistent queue.
     * @returns the LocalStorage key.
     */
    UserNamespaces.prototype.keyForEvent = function (pushedAt) {
        return "" + this.eventPrefix + pushedAt;
    };
    /**
     * Generates a key for a trigger that was already fired. This is used to make that trigger fire
     * only once for a given "context".
     * @param triggerName the name of the trigger.
     * @returns the key for the trigger name
     */
    UserNamespaces.prototype.keyForTrigger = function (triggerName) {
        return "" + this.triggerPrefix + triggerName;
    };
    return UserNamespaces;
}());
exports.UserNamespaces = UserNamespaces;
