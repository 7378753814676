"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
exports.__esModule = true;
var utils_1 = require("./utils");
var ua_parser_js_1 = require("ua-parser-js");
/**
 * The object received by the filter and metadata functions in the event trigger description. This
 * is a helper to make event trigger descriptions more easier to build.
 */
var Context = /** @class */ (function () {
    /**
     * Builds a new context from a tracker.
     * @param mockingjay the tracker to which this context is bound to.
     */
    function Context(mockingjay) {
        this.mockingjay = mockingjay;
        this._target = null;
        this._event = null;
    }
    /**
     *
     */
    Context.prototype.setTarget = function (selector) {
        this._target = document.querySelector(selector);
    };
    /**
     *
     */
    Context.prototype.target = function () {
        return this._target;
    };
    /**
     * Gets the JS event that trigged this event.
     */
    Context.prototype.event = function () {
        return this._event;
    };
    /**
     * Gets the user agent of the device.
     * @returns the user agent string for the device.
     */
    Context.prototype.userAgent = function () {
        var ua = new ua_parser_js_1.UAParser(navigator.userAgent);
        var browser = ua.getBrowser();
        var browserStr = browser.name + " " + browser.version;
        var os = ua.getOS();
        var osStr = os.name + " " + os.version;
        var device = ua.getDevice();
        var formatOpt = function (s) { return (s ? s + " " : ""); };
        var deviceStr = "" + formatOpt(device.vendor) + formatOpt(device.type) + formatOpt(device.model);
        return browserStr + " @ " + osStr + " @ " + (deviceStr == "" ? "unknown" : deviceStr.trim());
    };
    /**
     * Gets the value of a query parameter
     * @param paramName the query parameter name.
     * @returns the value of the query parameter or `null` if not present.
     */
    Context.prototype.queryParam = function (paramName) {
        return utils_1.readQuery(paramName);
    };
    /**
     * Gets the hash of the page URL or `null` if not present (or empty).
     */
    Context.prototype.urlHash = function () {
        var hash = new URL(window.location.toString()).hash;
        return hash == "" ? null : hash;
    };
    /**
     * Extracts the attribute value of the current target element.
     * @param attr the attribute of the element.
     * @returns the value of the attribute or `null` if element is not present or the
     *   attribute is not present in the element.
     */
    Context.prototype.attribute = function (attr) {
        if (this._target) {
            return this._target.getAttribute(attr);
        }
        else {
            return null;
        }
    };
    /**
     * Fetches all the UTM parameters in the query: `utm_source`, `utm_medium`, `utm_campaign`,
     * `utm_content` and `utm_term`.
     * @returns an object with all the UTM parameters found.
     */
    Context.prototype.utmParams = function () {
        return {
            utm_source: this.queryParam("utm_source"),
            utm_medium: this.queryParam("utm_medium"),
            utm_campaign: this.queryParam("utm_campaign"),
            utm_content: this.queryParam("utm_content"),
            utm_term: this.queryParam("utm_term"),
        };
    };
    /**
     * Fetches the `gclid` query parameter (this is a Google Ads tracking parameter).
     */
    Context.prototype.gclid = function () {
        return this.queryParam("gclid");
    };
    /**
     * Fetches the `fbclid` query parameter (this is a Facebook Ads tracking parameter).
     */
    Context.prototype.fbclid = function () {
        return this.queryParam("fbclid");
    };
    /**
     * Fetches the cookie value given a cookie name.
     * @param cookieName the name of the cookie or `null` if not found.
     */
    Context.prototype.cookie = function (cookieName) {
        return utils_1.readCookie(cookieName);
    };
    /**
     * Fetches the user UUID for an external system.
     * @param sourceSystem the name of the system.
     * @returns the UUID of the user, if found, else `null`.
     */
    Context.prototype.externalUserUuid = function (sourceSystem) {
        return utils_1.readCookie(this.mockingjay.namespaces.cookieName);
    };
    /**
     * Fetches the old user UUID if the the current session was imported from a different user.
     * @returns the old user UUID if present, else `null`.
     */
    Context.prototype.oldUserUuid = function () {
        return this.mockingjay.oldUserUuid;
    };
    /**
     * Fetches a value from local storage by key.
     * @param key the key in local storage.
     * @returns the value associated with the key or `null` if not found.
     */
    Context.prototype.localStorage = function (key) {
        return localStorage.getItem(key);
    };
    /**
     * Fetches a value from session storage by key.
     * @param key the key in session storage.
     * @returns the value associated with the key or `null` if not found.
     */
    Context.prototype.sessionStorage = function (key) {
        return sessionStorage.getItem(key);
    };
    /**
     * Fetches the current session id, defined as the timestamp of when the session was initiated.
     * @returns the session id of the current session or `null` when it could not be
     *   retrieved.
     */
    Context.prototype.sessionId = function () {
        return this.mockingjay.sessionId;
    };
    /**
     * Fetches informations of client, like IP, state, and others
     * @returns the object or `null` if not found.
     */
    Context.prototype.getClientInfo = function () {
        var clientInfo = this.mockingjay.clientPersistenceInfo.getClientInfo();
        if (clientInfo) {
            return {
                region_name: clientInfo.regionName,
                country: clientInfo.country,
                city: clientInfo.city,
                ip: clientInfo.ip
            };
        }
        return null;
    };
    /**
     * Fetches the referrer for this page.
     * @returns the referrer URI or null if no referrer.
     */
    Context.prototype.referrer = function () {
        if (document.referrer != "") {
            var referrer = new URL(document.referrer);
            referrer.search = "";
            referrer.hash = "";
            return referrer.toString();
        }
        else {
            return null;
        }
    };
    /**
     * Gets the form data from the current `target`.
     * @param fields
     * @returns an object with the translated keys or `null` if there is no target.
     */
    Context.prototype.formData = function (fields) {
        var e_1, _a, e_2, _b;
        try {
            if (this._target) {
                var values = new Object();
                if (Array.isArray(fields)) {
                    try {
                        for (var _c = __values(new FormData(this._target)), _d = _c.next(); !_d.done; _d = _c.next()) {
                            var _e = __read(_d.value, 2), name_1 = _e[0], value = _e[1];
                            if (fields.indexOf(name_1) != -1) {
                                values[name_1] = value;
                            }
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_d && !_d.done && (_a = _c["return"])) _a.call(_c);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                }
                else {
                    try {
                        for (var _f = __values(new FormData(this._target)), _g = _f.next(); !_g.done; _g = _f.next()) {
                            var _h = __read(_g.value, 2), name_2 = _h[0], value = _h[1];
                            if (name_2 in fields) {
                                values[fields[name_2]] = value;
                            }
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (_g && !_g.done && (_b = _f["return"])) _b.call(_f);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                }
                return values;
            }
            else {
                return null;
            }
        }
        catch (e) {
            console.error(e);
            return null;
        }
    };
    /**
     * Gets the first contentful paint for the webpage, if found.
     * @returns the first contentful paint measure or none otherwise.
     */
    Context.prototype.firstContentfulPaint = function () {
        try {
            var fcp = performance.getEntriesByName("first-contentful-paint")[0];
            if (fcp) {
                return fcp.startTime;
            }
            else {
                return null;
            }
        }
        catch (e) {
            console.error(e);
            return null;
        }
    };
    /**
     * Gets the time since the supplied time object. If `Date`, get `new Date()` since the date. If
     * `number`, get `performance.now()` since the number (these are relative to the page loading
     * time).
     * @param time
     * @returns the offset in `ms` between the dates.
     */
    Context.prototype.since = function (time) {
        if (time instanceof Date) {
            return new Date().getDate() - time.getTime();
        }
        else if (typeof time == "number") {
            return performance.now() - time;
        }
        else {
            return null;
        }
    };
    /**
     * Checks if the current page URL matches the supplied regex.
     * @param regex the regex against which the page should be matched.
     * @returns true if matches, false otherwise.
     */
    Context.prototype.pageLike = function (regex) {
        return !!window.location.toString().match(regex);
    };
    /**
     * Checks if the tracker has initiated a new user in this pageview.
     * @returns true if the user is new.
     */
    Context.prototype.isNewUser = function () {
        return this.mockingjay.userStatus == "new";
    };
    /**
     * Checks if the tracker initiated a new session in this pageview.
     * @returns true if the session is new.
     */
    Context.prototype.isNewSession = function () {
        return this.mockingjay.sessionStatus == "new";
    };
    /**
     * Checks if the form contains a list of fields.
     */
    Context.prototype.formContains = function (fields) {
        var e_3, _a;
        try {
            if (this._target) {
                var formData = new FormData(this._target);
                try {
                    for (var fields_1 = __values(fields), fields_1_1 = fields_1.next(); !fields_1_1.done; fields_1_1 = fields_1.next()) {
                        var name_3 = fields_1_1.value;
                        if (formData.get(name_3) == null) {
                            return false;
                        }
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (fields_1_1 && !fields_1_1.done && (_a = fields_1["return"])) _a.call(fields_1);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
                return true;
            }
            else {
                return false;
            }
        }
        catch (e) {
            console.error(e);
            return false;
        }
    };
    return Context;
}());
exports["default"] = Context;
