"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
exports.__esModule = true;
var utils_1 = require("./utils");
var localTrigged = new Set();
/**
 * An event trigger that triggers an event to be sent to the Event API on certain conditions.
 */
var EventTrigger = /** @class */ (function () {
    /**
     * Builds a new event trigger.
     * @param mockingjay the tracker to which this event trigger is associated.
     * @param name the name of this event trigger. This name is unique by trigger.
     * @param description the configuration of this trigger.
     */
    function EventTrigger(mockingjay, name, _a) {
        var event = _a.event, trigger = _a.trigger, once = _a.once, filter = _a.filter, metadata = _a.metadata;
        if (typeof event != "string") {
            throw new Error("`event` is not a string");
        }
        else if (!event) {
            throw new Error("`event` is empty string");
        }
        if (typeof trigger != "string") {
            throw new Error("`event` is not a string");
        }
        else if (!trigger) {
            throw new Error("`event` is empty string");
        }
        if (typeof filter != "function" && filter) {
            throw new Error("`filter` is not a function");
        }
        if (typeof metadata != "function" && metadata) {
            throw new Error("`metadata` is not a function");
        }
        this.mockingjay = mockingjay;
        this.name = name;
        this.event = event;
        this.trigger = parseTriggerExpr(trigger);
        this.triggerKey = mockingjay.userNamespaces.keyForTrigger(name);
        this.once = once !== null && once !== void 0 ? once : "no";
        this.filter = filter !== null && filter !== void 0 ? filter : (function (_) { return true; });
        this.metadata = metadata !== null && metadata !== void 0 ? metadata : (function () { return new Object(); });
    }
    /**
     * Marks the event as trigged.
     */
    EventTrigger.prototype.markTrigged = function () {
        if (this.once == "per user") {
            localStorage.setItem(this.triggerKey, "trigged");
        }
        else if (this.once == "per session") {
            sessionStorage.setItem(this.triggerKey, "trigged");
        }
        else if (this.once == "per page") {
            localTrigged.add(this.triggerKey);
        }
    };
    /**
     * Checks if the event was already trigged and.
     * @returns true if the event has already been trigged.
     */
    EventTrigger.prototype.wasAlreadyTrigged = function () {
        if (this.once == "per user") {
            return !!localStorage.getItem(this.triggerKey);
        }
        else if (this.once == "per session") {
            return !!sessionStorage.getItem(this.triggerKey);
        }
        else if (this.once == "per page") {
            return localTrigged.has(this.triggerKey);
        }
        else {
            return false;
        }
    };
    /**
     * Builds the metadata for the produced event.
     * @returns the metadata for the event.
     */
    EventTrigger.prototype.buildMetadata = function (context) {
        var globalMetadataConfig = this.mockingjay.planConfig.globalMetadata;
        var globalMetadata = globalMetadataConfig
            ? globalMetadataConfig(context)
            : {};
        var localMetadata = this.metadata(context);
        var raw = __assign(__assign({}, globalMetadata), localMetadata);
        // removes `null`s and `undefined`s (that's what `!=` does, exactly)
        return Object.fromEntries(Object.entries(raw).filter(function (_a) {
            var _b = __read(_a, 2), _ = _b[0], value = _b[1];
            return value != null;
        }));
    };
    /**
     * Dispatches an event, if it was triggered.
     * @returns
     */
    EventTrigger.prototype.dispatch = function (e) {
        var context = this.mockingjay.context;
        context._target = e ? e.target : undefined;
        context._event = e;
        if (!this.wasAlreadyTrigged() && this.filter(this.mockingjay.context)) {
            this.mockingjay.postEvent(this.event, this.buildMetadata(context));
            this.markTrigged();
        }
        return true;
    };
    /**
     * If an event on DOM elements, sets up the event to be trigged when the DOM event is fired.
     * @returns
     */
    EventTrigger.prototype.listen = function (listenContext) {
        var _this = this;
        this.trigger.listen(listenContext, function (e) { return _this.dispatch(e); }, this.mockingjay);
    };
    /**
     * Clears the environments from trigger firing information, resetting it.
     * @param userNamespaces the namespaces object for the current user.
     */
    EventTrigger.clear = function (userNamespaces) {
        // Clear once-per-user events:
        utils_1.clearPrefix(localStorage, userNamespaces.triggerPrefix);
        // Clear once-per-session events:
        utils_1.clearPrefix(sessionStorage, userNamespaces.triggerPrefix);
    };
    return EventTrigger;
}());
exports["default"] = EventTrigger;
function parseTriggerExpr(expr) {
    expr = expr.trim();
    if (expr == "user") {
        return new UserTriggerType();
    }
    else if (expr == "session") {
        return new SessionTriggerType();
    }
    else if (expr == "pageview") {
        return new PageViewTriggerType();
    }
    else if (expr.startsWith("apiListener;") && expr.split(";").length == 3) {
        var parts = expr.split(";");
        var method = parts[1].trim();
        var url = parts[2].trim();
        return new ApiTriggerType(method, url);
    }
    else if (expr.startsWith("every")) {
        return new IntervalTriggerType(parseFloat(expr.replace(/^every/, "").trim()));
    }
    else {
        var _a = __read(expr.split(" on ")), head = _a[0], tail = _a.slice(1);
        var selector = tail.join(" on ").trim();
        var isOnly = head.trim().endsWith("only");
        var eventName = head.trim().replace(/only$/, "").trim();
        return new EventTriggerType(selector, eventName, isOnly);
    }
}
var UserTriggerType = /** @class */ (function () {
    function UserTriggerType() {
    }
    UserTriggerType.prototype.listen = function (listenContext, dispatch) {
        if (listenContext == 0 /* User */) {
            dispatch(null);
        }
    };
    return UserTriggerType;
}());
var SessionTriggerType = /** @class */ (function () {
    function SessionTriggerType() {
    }
    SessionTriggerType.prototype.listen = function (listenContext, dispatch) {
        if (listenContext == 1 /* Session */) {
            dispatch(null);
        }
    };
    return SessionTriggerType;
}());
var PageViewTriggerType = /** @class */ (function () {
    function PageViewTriggerType() {
    }
    PageViewTriggerType.prototype.listen = function (listenContext, dispatch) {
        if (listenContext == 2 /* PageView */) {
            dispatch(null);
        }
    };
    return PageViewTriggerType;
}());
var ApiTriggerType = /** @class */ (function () {
    function ApiTriggerType(method, url) {
        this.method = method;
        this.url = url;
    }
    ApiTriggerType.prototype.listen = function (listenContext, dispatch, mockingjay) {
        if (listenContext == 2 /* PageView */) {
            var globalApiListener = mockingjay.globalApiListener;
            globalApiListener.listen(dispatch, this.method, this.url);
        }
    };
    return ApiTriggerType;
}());
var IntervalTriggerType = /** @class */ (function () {
    function IntervalTriggerType(every) {
        this.every = every;
    }
    IntervalTriggerType.prototype.listen = function (listenContext, dispatch) {
        if (listenContext == 2 /* PageView */) {
            setInterval(function () { return dispatch(null); }, this.every);
        }
    };
    return IntervalTriggerType;
}());
var EventTriggerType = /** @class */ (function () {
    function EventTriggerType(selector, eventName, isOnly) {
        this.selector = selector;
        this.eventName = eventName;
        this.isOnly = isOnly;
    }
    EventTriggerType.prototype.listen = function (listenContext, dispatch) {
        var _this = this;
        if (listenContext == 2 /* PageView */) {
            if (this.selector == "window") {
                window.addEventListener(this.eventName, dispatch);
            }
            else if (this.selector == "document") {
                document.addEventListener(this.eventName, dispatch);
            }
            else {
                document.addEventListener(this.eventName, function (e) {
                    if (e.target instanceof Element) {
                        if (_this.isOnly || !e.bubbles) {
                            if (e.target.matches(_this.selector)) {
                                dispatch(e);
                            }
                        }
                        else {
                            var element = e.target;
                            while (element != null) {
                                if (element.matches(_this.selector)) {
                                    dispatch(e);
                                    break;
                                }
                                element = element.parentElement;
                            }
                        }
                    }
                });
            }
        }
    };
    return EventTriggerType;
}());
