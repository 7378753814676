"use strict";
exports.__esModule = true;
exports.checkIfUrlsMatch = void 0;
/**
 * Check if mockingJay url listener match with the real url called in browser
 * @param configUrl the url configured in mockingJay
 * @param realUrl the url called by axios, fetch or another http call by browser and mockingJay got it by listener
 * @returns return true if url match
 * example 1: configUrl = https://example.com.br/user/{user}/role/{role} and realUrl = https://example.com.br/user/joao/role/admin/, should return true
 * example 2: configUrl = https://example.com.br/user/{user}/role/{role}/ and realUrl = https://example.com.br/user/joao/role/admin, should return true
 * example 3: configUrl = https://example.com.br/user/{user}/role/{role} and realUrl = https://example.com.br/user/joao/role/admin?param1=1&param2=2, should return true
 * example 4: configUrl = https://example.com.br/user/{user}/role/{role}/ and realUrl = https://example.com.br/user/joao/role, should return false
 */
function checkIfUrlsMatch(configUrl, realUrl) {
    configUrl = _sanitize(configUrl);
    realUrl = _sanitize(realUrl);
    var splittedConfigUrl = _removeEmptyFromArray(configUrl.split('/'));
    var splittedRealUrl = _removeEmptyFromArray(realUrl.split('/'));
    return _compareSplittedUrl(splittedConfigUrl, splittedRealUrl);
}
exports.checkIfUrlsMatch = checkIfUrlsMatch;
function _sanitize(url) {
    url = _sanitizeQueryParam(url);
    url = _sanitizeHttps(url);
    url = _sanitizeHttp(url);
    return url;
}
function _sanitizeQueryParam(url) {
    var split = url.split('?');
    return split[0];
}
function _sanitizeHttps(url) {
    var split = url.split('https://');
    return split[1] ? split[1] : split[0];
}
function _sanitizeHttp(url) {
    var split = url.split('http://');
    return split[1] ? split[1] : split[0];
}
function _removeEmptyFromArray(arr) {
    return arr.filter(function (a) {
        if (a.trim() !== '') {
            return a;
        }
    });
}
function _compareSplittedUrl(splittedConfigUrl, splittedRealUrl) {
    if (splittedConfigUrl.length !== splittedRealUrl.length) {
        return false;
    }
    var match = true;
    splittedConfigUrl.forEach(function (elConfigUrl, i) {
        var elRealUrl = splittedRealUrl[i];
        if (elConfigUrl == elRealUrl) {
            match = match && true;
        }
        else {
            if (elConfigUrl.includes('}')) {
                match = match && true;
            }
            else {
                match = match && false;
            }
        }
    });
    return match;
}
