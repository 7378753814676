"use strict";
exports.__esModule = true;
var utils_1 = require("./utils");
var GlobalApiListener = /** @class */ (function () {
    function GlobalApiListener(mockingjay) {
        this.mockingjay = mockingjay;
        this.open = XMLHttpRequest.prototype.open;
        this.send = XMLHttpRequest.prototype.send;
        this.dispatches = [];
    }
    GlobalApiListener.prototype.listen = function (dispatch, eventMethod, eventUrl) {
        // You need array of dispatches, because we cannot override one dispatch with another, inside of callback function of listener "load"
        this.dispatches.push({
            dispatch: dispatch,
            method: eventMethod,
            url: eventUrl
        });
        this._xmlHttpRequestOpen();
    };
    GlobalApiListener.prototype._xmlHttpRequestOpen = function () {
        var open = this.open;
        var xmlHttpRequestSend = this._xmlHttpRequestSend;
        var refClass = this;
        XMLHttpRequest.prototype.open = function (method, url) {
            var request = {
                method: method,
                url: url,
                body: null
            };
            xmlHttpRequestSend(refClass, request);
            open.apply(this, arguments);
        };
    };
    GlobalApiListener.prototype._xmlHttpRequestSend = function (refClass, request) {
        var send = refClass.send;
        XMLHttpRequest.prototype.send = function (body) {
            request.body = utils_1.stringToJson(body);
            this.addEventListener('load', function () {
                var response = {
                    status: this.status,
                    body: utils_1.stringToJson(this.response)
                };
                refClass._dispatchEvent(request, response);
            }, false);
            send.apply(this, arguments);
        };
    };
    GlobalApiListener.prototype._dispatchEvent = function (request, response) {
        this.dispatches.forEach(function (d) {
            if (utils_1.checkIfUrlsMatch(d.url, request.url) && request.method.toLowerCase() == d.method.toLowerCase()) {
                var event_1 = new CustomEvent("apiListener;" + d.method.toLowerCase() + ";" + d.url, {
                    detail: {
                        request: request,
                        response: response
                    }
                });
                d.dispatch(event_1);
            }
        });
    };
    return GlobalApiListener;
}());
exports["default"] = GlobalApiListener;
