"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
exports.__esModule = true;
exports.mockingjayInit = void 0;
var utils_1 = require("./utils");
var eventTrigger_1 = require("./eventTrigger");
var context_1 = require("./context");
var globalApiListener_1 = require("./globalApiListener");
var persistenceInfo_1 = require("./persistenceInfo");
var crossDomainToken_1 = require("./crossDomainToken");
var namespaces_1 = require("./namespaces");
var linkParametrizer_1 = require("./linkParametrizer");
/**
 * The tracking system.
 */
var Mockingjay = /** @class */ (function () {
    /**
     * Instantiates a new tracker.
     * @param sourceSystem the name of the system for which this tracker logs events.
     * @param options the configuration options for the trackers. Pass an empty object to use
     *   standard values.
     * @param plan an object whose entries are the descriptions of the events triggers that
     *   this tracker will listen to. You can also track events directly.
     */
    function Mockingjay(sourceSystem, _a, plan) {
        var _this = this;
        var refreshInterval = _a.refreshInterval, crossDomainHosts = _a.crossDomainHosts;
        // Log that you are starting.
        console.log("[LOG " + new Date().toISOString() + "] Initializing mockingjay for " + sourceSystem);
        this.clientPersistenceInfo = new persistenceInfo_1.ClientPersistenceInfo();
        // Validate the source system name.
        if (!sourceSystem) {
            throw new Error("`source-system` is not defined");
        }
        else if (typeof sourceSystem != "string") {
            throw new Error("`source-system` is not a string");
        }
        // Load source system name and create the namespace for the source system.
        this.sourceSystem = sourceSystem;
        this.namespaces = new namespaces_1.Namespaces(sourceSystem);
        // Gather (and set) the persistence information, using cookies, storages and link params:
        var persistenceInfo = new persistenceInfo_1["default"](this.namespaces);
        this.userUuid = persistenceInfo.userUuid;
        this.userStatus = persistenceInfo.userStatus;
        this.oldUserUuid = persistenceInfo.oldUserUuid;
        this.sessionId = persistenceInfo.sessionId;
        this.sessionStatus = persistenceInfo.sessionStatus;
        var userPersistenceInfo = new persistenceInfo_1.UserPersistenceInfo(this.userUuid, this.sourceSystem);
        var user = userPersistenceInfo.getUserInfo();
        if (user.email) {
            this.userEmail = user.email;
        }
        if (user.name) {
            this.userName = user.name;
        }
        if (user.externalId) {
            this.userExternalId = user.externalId;
        }
        // Create the namespace for the user in the source system.
        this.userNamespaces = new namespaces_1.UserNamespaces(this.namespaces, this.userUuid);
        // Load the plan; leave the configuration out of it (messy!)
        this.plan = Object.fromEntries(Object.entries(plan)
            .filter(function (_a) {
            var _b = __read(_a, 2), key = _b[0], _ = _b[1];
            return key != "config";
        })
            .map(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            return [
                key,
                new eventTrigger_1["default"](_this, key, value),
            ];
        }));
        this.planConfig = plan.config || {};
        // Create hte context object to be used when creating the metadata.
        this.context = new context_1["default"](this);
        // Create a listener to listen to global API calls.
        this.globalApiListener = new globalApiListener_1["default"](this);
        // And don't forget to reparametrize all the links in the page, when the document is ready.
        var crossOrigins = new Set(utils_1.domainsToOrigins(crossDomainHosts || []));
        if (crossOrigins.size > 0) {
            utils_1.notBeforeLoad(function () {
                _this.linkParametrizer = new linkParametrizer_1["default"](_this.namespaces, _this.userUuid, _this.sessionId, refreshInterval || 60000, crossOrigins);
            });
        }
        console.log("[LOG " + new Date().toISOString() + "] Initialized mockingjay for " + sourceSystem + ", user - " + this.userUuid + ", session - " + this.sessionId);
    }
    /**
     * Initiates a new tracker, _if one is not already instantiated_ and loads it to
     * `window.mockingjay`. Use *this* method instead of the constructor. It avoids instantiating the
     * tracker twice.
     * @param sourceSystem the name of the system for which this tracker logs events.
     * @param config the configuration options for the trackers. Pass an empty object to use
     *   standard values.
     * @param plan an object whose entries are the descriptions of the events triggers that
     *   this tracker will listen to. You can also track events directly.
     * @returns a new tracker, now also stored in `window.mockingjay`, or the old
     * tracker, if one has already been instantiated. In this case, no new tracker is created.
     */
    Mockingjay.init = function (sourceSystem, config, plan) {
        var _a;
        window.mockingjay =
            (_a = window.mockingjay) !== null && _a !== void 0 ? _a : new Mockingjay(sourceSystem, config, plan).start();
        return window.mockingjay;
    };
    Mockingjay.logout = function () {
        var _a;
        (_a = window.mockingjay) === null || _a === void 0 ? void 0 : _a.logout();
    };
    Mockingjay.setUserInfo = function (user) {
        var _a;
        (_a = window.mockingjay) === null || _a === void 0 ? void 0 : _a.setUserInfo(user);
    };
    Mockingjay.prototype.setSendDateOnMetadata = function (metadata) {
        var dateLocalTz = utils_1.getCurrentDateLocalTZ();
        var dateUTC = utils_1.getCurrentDateUTC();
        metadata.mocking_jay_date_local_tz = dateLocalTz;
        metadata.mocking_jay_date_utc = dateUTC;
        return metadata;
    };
    /**
     * Builds an event to be sent to the Event API
     * @param event the name of the event to be sent. Recommended to be a CamelCase class
     *   name.
     * @param metadata the object of keys and values that form the metadata of this event.
     */
    Mockingjay.prototype.buildEvent = function (event, metadata) {
        var url = new URL(window.location.toString());
        url.search = "";
        url.hash = "";
        var eventRequest;
        metadata = this.setSendDateOnMetadata(metadata);
        eventRequest = {
            async: "true",
            uniqueness: utils_1.generateUuid(),
            source_url: url.toString(),
            source_system: this.sourceSystem,
            user_uuid: this.userUuid,
            event: event,
            metadata: JSON.stringify(metadata),
        };
        if (this.userEmail) {
            eventRequest.user_email = this.userEmail;
        }
        if (this.userName) {
            eventRequest.user_name = this.userName;
        }
        if (this.userExternalId) {
            eventRequest.user_external_id = this.userExternalId;
        }
        return eventRequest;
    };
    /**
     * Posts an event to the Event API.
     * @param event the name of the event to be sent. Recommended to be a CamelCase class
     *   name.
     * @param metadata the object of keys and values that form the metadata of this event.
     */
    Mockingjay.prototype.postEvent = function (event, metadata) {
        if (ENV == "DEVELOPMENT") {
            console.log("[LOG " + new Date().toISOString() + "] Sending", event, metadata);
        }
        var request = this.buildEvent(event, metadata);
        navigator.sendBeacon(API_BASE_URL, new URLSearchParams(request));
        return this;
    };
    /**
     * Starts the tracker system.
     * TODO: should I put this in the constructor instead?
     */
    Mockingjay.prototype.start = function () {
        var _this = this;
        utils_1.notBeforeLoad(function () { return __awaiter(_this, void 0, void 0, function () {
            var dispatchInContext;
            var _this = this;
            return __generator(this, function (_a) {
                dispatchInContext = function (listenContext) {
                    Object.values(_this.plan).forEach(function (trigger) {
                        return trigger.listen(listenContext);
                    });
                };
                // New user triggers:
                if (this.userStatus == "new") {
                    dispatchInContext(0 /* User */);
                }
                // New session triggers:
                if (this.sessionStatus == "new") {
                    dispatchInContext(1 /* Session */);
                }
                // Page-view triggers:
                dispatchInContext(2 /* PageView */);
                return [2 /*return*/];
            });
        }); });
        return this;
    };
    /**
     * Builds and encodes a new, fresh cross domain token.
     */
    Mockingjay.prototype.crossDomainToken = function () {
        var _a;
        return (_a = this.linkParametrizer) === null || _a === void 0 ? void 0 : _a.crossDomainToken();
    };
    /**
     * Returns the encoded key of the cross domain token.
     */
    Mockingjay.prototype.crossDomainKey = function () {
        return this.namespaces.tokenParam;
    };
    /**
    * Set the user's information, to send to the Event API.
    */
    Mockingjay.prototype.setUserInfo = function (user) {
        var userPersistenceInfo = new persistenceInfo_1.UserPersistenceInfo(this.userUuid, this.sourceSystem);
        this.userEmail = user.email;
        this.userName = user.name;
        this.userExternalId = user.externalId;
        if (user.email && user.email !== '') {
            userPersistenceInfo.setEmail(user.email);
        }
        if (user.name && user.name !== '') {
            userPersistenceInfo.setName(user.name);
        }
        if (user.externalId && user.externalId !== '') {
            userPersistenceInfo.setExternalId(user.externalId);
        }
    };
    /**
     * Requests the tracking system to forget everything about the current user and start over the
     * next page. However, this will take effect only on the next page load.
     */
    Mockingjay.prototype.logout = function () {
        var userUuid = this.userUuid;
        var sourceSystem = this.sourceSystem;
        // Clear triggers:
        eventTrigger_1["default"].clear(this.userNamespaces);
        // Clear session info:
        sessionStorage.removeItem(this.namespaces.sessionKey);
        // Destroy user info:
        utils_1.unsetCookie(this.namespaces.cookieName);
        // Clear user info:
        var userPersistenceInfo = new persistenceInfo_1.UserPersistenceInfo(userUuid, sourceSystem);
        userPersistenceInfo.clear();
        console.log("[LOG " + new Date().toISOString() + "] Logout for user - " + userUuid + " completed. Reload page to get a new user uuid and a new session.");
        // Bye-bye!!
        return this;
    };
    Mockingjay.CrossDomainToken = crossDomainToken_1["default"];
    return Mockingjay;
}());
exports["default"] = Mockingjay;
/**
 * DEPRECATED: use `Mockingjay.init` instead.
 * @deprecated
 * @param sourceSystem
 * @param config
 * @param plan
 */
function mockingjayInit(sourceSystem, config, plan) {
    return Mockingjay.init(sourceSystem, config, plan);
}
exports.mockingjayInit = mockingjayInit;
