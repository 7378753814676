"use strict";
// In Soviet Union, you listen to MockingjayJS!
//
exports.__esModule = true;
require("./polyfill");
var mockingjay_1 = require("./mockingjay");
var ua_parser_js_1 = require("ua-parser-js");
window.Mockingjay = mockingjay_1["default"];
// DEPRECATED. Use `Mockingjay.init` instead.
window.mockingjayInit = mockingjay_1.mockingjayInit;
var base64_arraybuffer_1 = require("base64-arraybuffer");
window.encode = base64_arraybuffer_1.encode;
window.decode = base64_arraybuffer_1.decode;
var crossDomainToken_1 = require("./crossDomainToken");
window.CrossDomainToken = crossDomainToken_1["default"];
window.UAParser = ua_parser_js_1.UAParser;
