"use strict";
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
exports.__esModule = true;
exports.getCurrentDateUTC = exports.getCurrentDateLocalTZ = exports.stringToJson = exports.clearPrefix = exports.iterPrefix = exports.domainsToOrigins = exports.notBeforeLoad = exports.generateUuid = exports.readQuery = exports.unsetCookie = exports.setCookie = exports.readCookie = exports.checkIfUrlsMatch = void 0;
var checkIfUrlsMatch_1 = require("./checkIfUrlsMatch");
exports.checkIfUrlsMatch = checkIfUrlsMatch_1.checkIfUrlsMatch;
/**
 * Gets a cookie by name.
 * @param {string} name the name of the cookie
 * @returns {string} the current value of the cookie or `null` if not present
 */
function readCookie(name) {
    var e_1, _a;
    var nameEquals = name + "=";
    var decoded = decodeURIComponent(document.cookie);
    var cookies = decoded.split(";").map(function (untrimmed) { return untrimmed.trim(); });
    try {
        for (var cookies_1 = __values(cookies), cookies_1_1 = cookies_1.next(); !cookies_1_1.done; cookies_1_1 = cookies_1.next()) {
            var cookie = cookies_1_1.value;
            if (cookie.indexOf(nameEquals) == 0) {
                return cookie.substring(nameEquals.length, cookie.length);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (cookies_1_1 && !cookies_1_1.done && (_a = cookies_1["return"])) _a.call(cookies_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return null;
}
exports.readCookie = readCookie;
/**
 * Sets a cookie value by name. The cookie expires in a year and is "same site".
 * @param {string} name the name of the cookie
 * @param {any} value the new value of the cookie
 */
function setCookie(name, value) {
    var date = new Date();
    date.setTime(date.getTime() + 365.25 * 24 * 60 * 60 * 1000);
    document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=/; SameSite=Lax";
}
exports.setCookie = setCookie;
/**
 * Unset a cookie by name.
 * @param {string} name the name of the cookie to be unset.
 * @returns {void}
 */
function unsetCookie(name) {
    var date = new Date();
    date.setTime(date.getTime() - 365.25 * 24 * 60 * 60 * 1000);
    document.cookie = name + "=bye-bye; expires=" + date.toUTCString() + "; path=/; SameSite=Lax";
}
exports.unsetCookie = unsetCookie;
/**
 * Gets a query parameter by name.
 * @param name the name of the query parameter
 * @returns the value of the query parameter or `null` if not present.
 */
function readQuery(name) {
    var e_2, _a;
    var entries = new URLSearchParams(window.location.search).entries();
    try {
        for (var entries_1 = __values(entries), entries_1_1 = entries_1.next(); !entries_1_1.done; entries_1_1 = entries_1.next()) {
            var _b = __read(entries_1_1.value, 2), key = _b[0], value = _b[1];
            if (key == name) {
                return value;
            }
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (entries_1_1 && !entries_1_1.done && (_a = entries_1["return"])) _a.call(entries_1);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return null;
}
exports.readQuery = readQuery;
/**
 * Generates a UUID version 4 at random
 * @returns a valid UUID version 4 string.
 */
function generateUuid() {
    // UUIDs must be of the form "xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx", where x is one of
    // [0-9, a-f] M is one of [1-5], and N is [8, 9, a, or b]
    var hex = function () { return Math.floor(Math.random() * 16).toString(16); };
    var hexs = function (n) { return new Array(n).fill(undefined).map(hex).join(""); };
    var choose = function (list) {
        return list[Math.floor(Math.random() * list.length)];
    };
    var flag = function () { return choose(["8", "9", "a", "b"]); };
    return hexs(8) + "-" + hexs(4) + "-4" + hexs(3) + "-" + flag() + hexs(3) + "-" + hexs(12);
}
exports.generateUuid = generateUuid;
/**
 * Sets a function not before the document loads. This is different from `document.onload` because
 * the function is always guaranteed to run.
 */
function notBeforeLoad(func) {
    if (document.readyState == "interactive" ||
        document.readyState == "complete") {
        func();
    }
    else {
        var pesterer_1 = setInterval(function () {
            if (document.readyState == "interactive" ||
                document.readyState == "complete") {
                clearInterval(pesterer_1);
                func();
            }
        }, 200);
    }
}
exports.notBeforeLoad = notBeforeLoad;
/**
 * Returns a list of origins crossing a list of domain names with the protocols HTTP and HTTPS.
 * @param domains a list of domains for which the origins should be listed.
 * @returns a list of origins
 */
function domainsToOrigins(domains) {
    return __spreadArray(__spreadArray([], __read(domains.map(function (domain) { return new URL("https://" + domain).origin; }))), __read(domains.map(function (domain) { return new URL("http://" + domain).origin; })));
}
exports.domainsToOrigins = domainsToOrigins;
/**
 * Iterates over all key-value pairs for a given prefix in a given storage.
 * @param store the storage in which the iteration will happen
 * @param prefix the prefix over which to iterate.
 */
function iterPrefix(store, prefix) {
    var i, key;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                i = 0;
                _a.label = 1;
            case 1:
                if (!(i < store.length)) return [3 /*break*/, 4];
                key = store.key(i);
                if (!key.startsWith(prefix)) return [3 /*break*/, 3];
                return [4 /*yield*/, [key, store.getItem(key)]];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3:
                i++;
                return [3 /*break*/, 1];
            case 4: return [2 /*return*/];
        }
    });
}
exports.iterPrefix = iterPrefix;
/**
 * Clears all keys with a given prefix in a given storage.
 * @param store the storage in which to clear the prefixes.
 * @param prefix the prefix of the keys to be cleared.
 */
function clearPrefix(store, prefix) {
    var e_3, _a;
    // Need to materialize first. Classical error to iterate while mutating.
    var keys = __spreadArray([], __read(iterPrefix(store, prefix)));
    try {
        for (var keys_1 = __values(keys), keys_1_1 = keys_1.next(); !keys_1_1.done; keys_1_1 = keys_1.next()) {
            var _b = __read(keys_1_1.value, 2), key = _b[0], _ = _b[1];
            store.removeItem(key);
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (keys_1_1 && !keys_1_1.done && (_a = keys_1["return"])) _a.call(keys_1);
        }
        finally { if (e_3) throw e_3.error; }
    }
}
exports.clearPrefix = clearPrefix;
/**
 * Convert a string to json.
 * @param value string to json converter.
 * @returns a json object or null if the string is not json
 */
function stringToJson(value) {
    try {
        return JSON.parse(value);
    }
    catch (e) {
        return value;
    }
}
exports.stringToJson = stringToJson;
function getCurrentDateLocalTZ() {
    var now = new Date();
    var year = now.getFullYear();
    var month = ("0" + (now.getMonth() + 1)).slice(-2);
    var day = ("0" + now.getDate()).slice(-2);
    var hour = ("0" + now.getHours()).slice(-2);
    var minute = ("0" + now.getMinutes()).slice(-2);
    var second = ("0" + now.getSeconds()).slice(-2);
    return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
}
exports.getCurrentDateLocalTZ = getCurrentDateLocalTZ;
function getCurrentDateUTC() {
    var now = new Date();
    var year = now.getUTCFullYear();
    var month = ("0" + (now.getUTCMonth() + 1)).slice(-2);
    var day = ("0" + now.getUTCDate()).slice(-2);
    var hour = ("0" + now.getUTCHours()).slice(-2);
    var minute = ("0" + now.getUTCMinutes()).slice(-2);
    var second = ("0" + now.getUTCSeconds()).slice(-2);
    return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
}
exports.getCurrentDateUTC = getCurrentDateUTC;
