"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
exports.ClientPersistenceInfo = exports.UserPersistenceInfo = void 0;
var crossDomainToken_1 = require("./crossDomainToken");
var utils_1 = require("./utils");
/**
 * The object that manages the persistence information present in the page environment, be it
 * cookies, storages or imported URL query parameters.
 */
var PersistenceInfo = /** @class */ (function () {
    function PersistenceInfo(namespaces) {
        var _a;
        var maybeToken = crossDomainToken_1["default"].decode((_a = utils_1.readQuery(namespaces.tokenParam)) !== null && _a !== void 0 ? _a : "");
        var maybeCookie = utils_1.readCookie(namespaces.cookieName);
        if (maybeCookie &&
            maybeToken &&
            maybeToken.isValid() &&
            maybeCookie != maybeToken.userUuid) {
            // Then we have received the persistence info via query params.
            utils_1.setCookie(namespaces.cookieName, maybeToken.userUuid);
            this.userUuid = maybeToken.userUuid;
            this.oldUserUuid = maybeCookie;
            this.userStatus = "imported";
        }
        else if (!maybeCookie) {
            // Then we are a fresh new user (or not; cross-device).
            var newId = utils_1.generateUuid();
            utils_1.setCookie(namespaces.cookieName, newId);
            this.userUuid = newId;
            this.oldUserUuid = null;
            this.userStatus = "new";
        }
        else {
            // ... or else, use current cookie.
            this.userUuid = maybeCookie;
            this.oldUserUuid = null;
            this.userStatus = "existing";
        }
        // Always import session, if you can.
        var maybeStoredSession = sessionStorage.getItem(namespaces.sessionKey);
        if ((this.userStatus == "imported" || this.userStatus == "existing") &&
            maybeToken &&
            maybeToken.isValid()) {
            sessionStorage.setItem(namespaces.sessionKey, maybeToken.sessionId.toString());
            this.sessionId = maybeToken.sessionId;
            this.sessionStatus = "imported";
        }
        else if (maybeStoredSession) {
            this.sessionId = parseInt(maybeStoredSession);
            this.sessionStatus = "existing";
        }
        else {
            this.sessionId = new Date().getTime();
            sessionStorage.setItem(namespaces.sessionKey, this.sessionId.toString());
            this.sessionStatus = "new";
        }
    }
    return PersistenceInfo;
}());
exports["default"] = PersistenceInfo;
var UserPersistenceInfo = /** @class */ (function () {
    function UserPersistenceInfo(userUuid, sourceSystem) {
        this.base = "userInfo";
        this.userUuid = userUuid;
        this.sourceSystem = sourceSystem;
    }
    UserPersistenceInfo.prototype.setName = function (name) {
        sessionStorage.setItem(this.sourceSystem + ":" + this.userUuid + ":" + this.base + ":name", name);
    };
    UserPersistenceInfo.prototype.setEmail = function (email) {
        sessionStorage.setItem(this.sourceSystem + ":" + this.userUuid + ":" + this.base + ":email", email);
    };
    UserPersistenceInfo.prototype.setExternalId = function (externalId) {
        sessionStorage.setItem(this.sourceSystem + ":" + this.userUuid + ":" + this.base + ":externalId", externalId);
    };
    UserPersistenceInfo.prototype.getUserInfo = function () {
        var user = {};
        var name = sessionStorage.getItem(this.sourceSystem + ":" + this.userUuid + ":" + this.base + ":name");
        var email = sessionStorage.getItem(this.sourceSystem + ":" + this.userUuid + ":" + this.base + ":email");
        var externalId = sessionStorage.getItem(this.sourceSystem + ":" + this.userUuid + ":" + this.base + ":externalId");
        if (name && name !== '') {
            user.name = name;
        }
        if (email && email !== '') {
            user.email = email;
        }
        if (externalId && externalId !== '') {
            user.externalId = externalId;
        }
        return user;
    };
    UserPersistenceInfo.prototype.clear = function () {
        sessionStorage.removeItem(this.sourceSystem + ":" + this.userUuid + ":" + this.base + ":name");
        sessionStorage.removeItem(this.sourceSystem + ":" + this.userUuid + ":" + this.base + ":email");
        sessionStorage.removeItem(this.sourceSystem + ":" + this.userUuid + ":" + this.base + ":externalId");
    };
    return UserPersistenceInfo;
}());
exports.UserPersistenceInfo = UserPersistenceInfo;
var ClientPersistenceInfo = /** @class */ (function () {
    function ClientPersistenceInfo() {
        if (this._checkIfCanInit()) {
            this._initClientInfo();
        }
    }
    ClientPersistenceInfo.prototype._checkIfCanInit = function () {
        var loadTime = Number(sessionStorage.getItem('lastUpdateClientInfo'));
        var currentTime = (new Date().getTime()) / 1000;
        if (currentTime - loadTime >= 60 * 60) { // 1 hour
            return true;
        }
        else {
            return false;
        }
    };
    ClientPersistenceInfo.prototype._initClientInfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    // let response = await fetch("http://ip-api.com/json")
                    // let data = await response.json()
                    // if (data.status === 'success') {
                    //   if (data.query) {
                    //     sessionStorage.setItem(`clientInfoIp`, data.query);
                    //   }
                    //   if (data.country) {
                    //     sessionStorage.setItem(`clientInfoCountry`, data.country);
                    //   }
                    //   if (data.regionName) {
                    //     sessionStorage.setItem(`clientInfoRegionName`, data.regionName);
                    //   }
                    //   if (data.city) {
                    //     sessionStorage.setItem(`clientInfoCity`, data.city);
                    //   }
                    //   let loadTime = (new Date().getTime()) / 1000;
                    //   sessionStorage.setItem('lastUpdateClientInfo', loadTime.toString());
                    // }
                }
                catch (e) { }
                return [2 /*return*/];
            });
        });
    };
    ClientPersistenceInfo.prototype.getClientInfo = function () {
        var clientInfoCity = sessionStorage.getItem("clientInfoCity");
        var clientInfoCountry = sessionStorage.getItem("clientInfoCountry");
        var clientInfoRegionName = sessionStorage.getItem("clientInfoRegionName");
        var clientInfoIp = sessionStorage.getItem("clientInfoIp");
        var clientInfo = {
            city: clientInfoCity,
            country: clientInfoCountry,
            regionName: clientInfoRegionName,
            ip: clientInfoIp
        };
        if (clientInfo.city && clientInfo.country && clientInfo.regionName && clientInfo.ip) {
            return clientInfo;
        }
        else {
            return null;
        }
    };
    return ClientPersistenceInfo;
}());
exports.ClientPersistenceInfo = ClientPersistenceInfo;
