"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
exports.__esModule = true;
var crossDomainToken_1 = require("./crossDomainToken");
/**
 * The object managing anchor `href` reparametrization in order to carry the cross-site information.
 */
var LinkParametrizer = /** @class */ (function () {
    function LinkParametrizer(namespaces, userUuid, sessionId, refreshInterval, crossOrigins) {
        this.userUuid = userUuid;
        this.sessionId = sessionId;
        this.refreshInterval = refreshInterval;
        this.namespaces = namespaces;
        this.crossOrigins = crossOrigins;
        var _a = this.findAndParametrize(), observer = _a.observer, refresher = _a.refresher;
        this.observer = observer;
        this.refresher = refresher;
    }
    /**
     * Builds and encodes a new and fresh cross domain token.
     */
    LinkParametrizer.prototype.crossDomainToken = function () {
        return new crossDomainToken_1["default"](this.userUuid, this.sessionId, new Date().getTime() + this.refreshInterval).encode();
    };
    LinkParametrizer.prototype.parametrizeAnchor = function (anchor) {
        var _a;
        try {
            var url = new URL(anchor.href);
            var here = new URL(window.location.toString());
            // Cases in which parametrization is skipped:
            var mustNeverReparametrize = (url.origin == here.origin && url.pathname == here.pathname) ||
                ((_a = anchor.getAttribute("href")) !== null && _a !== void 0 ? _a : "").startsWith("#"); // to be sure...
            if (mustNeverReparametrize) {
                return;
            }
            var token = this.crossDomainToken();
            // Need to explicitly check if param is set to avoid an infite loop. Remember: we are
            // mutating the anchor here, after all.
            // NOTE: no, cannot exclude internal links here!! Internal links can be redirects,
            // which make them external.
            var mustReparametrize = url.searchParams.get(this.namespaces.tokenParam) != token;
            if (mustReparametrize && this.crossOrigins.has(url.origin)) {
                url.searchParams.set(this.namespaces.tokenParam, token);
                anchor.href = url.href;
            }
        }
        catch (e) {
            if (!(e instanceof TypeError)) {
                throw e;
            }
        }
    };
    LinkParametrizer.prototype.parametrizeForm = function (form) {
        try {
            var url = new URL(form.action);
            var here = new URL(window.location.toString());
            // Cases in which parametrization is skipped:
            var mustNeverReparametrize = (url.origin == here.origin && url.pathname == here.pathname);
            if (mustNeverReparametrize) {
                return;
            }
            var token = this.crossDomainToken();
            // Need to explicitly check if param is set to avoid an infite loop. Remember: we are
            // mutating the anchor here, after all.
            // NOTE: no, cannot exclude internal links here!! Internal links can be redirects,
            // which make them external.
            var mustReparametrize = url.searchParams.get(this.namespaces.tokenParam) != token;
            if (mustReparametrize && this.crossOrigins.has(url.origin)) {
                url.searchParams.set(this.namespaces.tokenParam, token);
                form.action = url.href;
            }
        }
        catch (e) {
            if (!(e instanceof TypeError)) {
                throw e;
            }
        }
    };
    /**
     * Finds all anchors in a page and make their `href` attribute carry the cross-site parameters if
     * they are valid URLs. This function will refresh the parameters from time to time to conform with
     * the freshness proof required to load the user uuid.
     * @param userUuid the current user uuid.
     * @param sessionId the current session id.
     * @param refreshInterval the amount of time for which the link is considered fresh.
     * @param crossOrigins the origins on which to rewrite the URLs. This prevents our user uuid
     *  from leaking to third-party sites.
     * @returns the mutation observer for the anchors.
     */
    LinkParametrizer.prototype.findAndParametrize = function () {
        var _this = this;
        var parametrizeAll = function () {
            var e_1, _a, e_2, _b;
            try {
                for (var _c = __values(document.getElementsByTagName("A")), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var anchor = _d.value;
                    _this.parametrizeAnchor(anchor);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c["return"])) _a.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
            try {
                for (var _e = __values(document.getElementsByTagName("FORM")), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var form = _f.value;
                    _this.parametrizeForm(form);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_b = _e["return"])) _b.call(_e);
                }
                finally { if (e_2) throw e_2.error; }
            }
        };
        // do this *right now*.
        parametrizeAll();
        // do this a bit more frequent than the refresh interval because systems are laggy...
        var refresher = window.setInterval(parametrizeAll, this.refreshInterval * 0.5);
        var observer = new MutationObserver(function (mutations) {
            var e_3, _a, e_4, _b;
            try {
                //const freshness = new Date().getTime() + this.refreshInterval;
                for (var mutations_1 = __values(mutations), mutations_1_1 = mutations_1.next(); !mutations_1_1.done; mutations_1_1 = mutations_1.next()) {
                    var mutationRecord = mutations_1_1.value;
                    try {
                        for (var _c = (e_4 = void 0, __values(mutationRecord.addedNodes)), _d = _c.next(); !_d.done; _d = _c.next()) {
                            var node = _d.value;
                            if (node instanceof HTMLAnchorElement) {
                                _this.parametrizeAnchor(node);
                            }
                            else if (node instanceof HTMLFormElement) {
                                _this.parametrizeForm(node);
                            }
                        }
                    }
                    catch (e_4_1) { e_4 = { error: e_4_1 }; }
                    finally {
                        try {
                            if (_d && !_d.done && (_b = _c["return"])) _b.call(_c);
                        }
                        finally { if (e_4) throw e_4.error; }
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (mutations_1_1 && !mutations_1_1.done && (_a = mutations_1["return"])) _a.call(mutations_1);
                }
                finally { if (e_3) throw e_3.error; }
            }
        });
        observer.observe(document.body, {
            // WARNING: this is quite dangerous. Very easy to get uncontrolled recursion.
            // attributes: true,
            // attributeFilter: ["href"],
            // Only element creation:
            childList: true,
            subtree: true,
        });
        return { observer: observer, refresher: refresher };
    };
    return LinkParametrizer;
}());
exports["default"] = LinkParametrizer;
